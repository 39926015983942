/* Buttons */
.ant-btn {
  text-transform: capitalize;
  box-shadow: none;
}
.ant-btn-primary:hover:not(:disabled),
.ant-btn-primary:active:not(:disabled) {
  @apply text-navy-1 !important;
}

/*
 * Menu
 *
 * These styles are copied from ant-menu-item so that submenu titles that
 * include a link are only highlighted when we are on the current page.
 */
.ant-menu-submenu-title a {
  color: inherit;
}
.ant-menu-submenu-title a:hover {
  color: inherit;
}

/*
 * bb-menu-flat
 *
 * Class for flattening a menu so we can apply explicit indents.
 */

.ant-menu-inline .bb-menu-flat[role="menuitem"],
.ant-menu-inline .bb-menu-flat [role="menuitem"] {
  padding-left: 0 !important;
}

.ant-menu-inline .bb-menu-level-1[role="menuitem"],
.ant-menu-inline .bb-menu-level-1 > [role="menuitem"] {
  padding-left: 24px !important;
}

.ant-menu-inline .bb-menu-level-2[role="menuitem"],
.ant-menu-inline .bb-menu-level-2 > [role="menuitem"] {
  padding-left: 48px !important;
}

.ant-menu-inline .bb-menu-level-3[role="menuitem"],
.ant-menu-inline .bb-menu-level-3 > [role="menuitem"] {
  padding-left: 72px !important;
}

/*
 * bb-menu-wrap
 *
 * Allows menu items to wrap.
 */
.ant-menu-inline .bb-menu-wrap[role="menuitem"],
.ant-menu-inline .bb-menu-wrap [role="menuitem"],
.ant-menu-inline .bb-menu-wrap .ant-menu-title-content {
  @apply text-wrap leading-normal h-min py-[4.75px] !important;
}

/* Collapse */
.biobot-collapse .ant-collapse-header {
  @apply font-medium font-sans text-xl py-1 !important;
}

.biobot-collapse .anticon.anticon-caret-right.ant-collapse-arrow {
  @apply text-xl -ml-5 mt-3 !important;
}

/* Sider */
.ant-layout .ant-layout-sider {
  border-right: 1px solid theme(colors[navy-2]);
}

/* Legends & Methods Table */
.legend-table th,
.legend-table td {
  font-size: 15px;
  @apply text-navy-7 !important;
}
.legend-table td {
  background: none !important;
  box-shadow: -2px 2px 6px theme(colors[navy-1]);
}

/* Summary Chart Legend */
.ant-space.force-ellipsis > :last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Homepage Tabs */
.ant-tabs-ink-bar {
  background: theme(colors[yellow-5]) !important;
}

/* Datepicker */
.ant-picker-presets ul li {
  list-style-type: none;
}
