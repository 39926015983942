@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./fonts/gt-america.css);
@import url(./theme/overrides.css);

@layer base {
  body {
    margin: 0;
    @apply font-sans font-normal text-sm text-navy-7;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  li {
    list-style: disc;
    list-style-position: inside;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

@layer utilities {
  .stroke-dashed {
    stroke-dasharray: 3;
  }

  .stroke-dotted {
    stroke-dasharray: 1 4;
  }

  /* Default Text Styles */
  .text-h1 {
    @apply font-sans font-medium text-3xl mt-2 mb-2 text-navy-9;
  }

  .text-h2 {
    @apply font-sans font-thin text-2xl text-navy-9;
  }

  .text-h3 {
    @apply font-sans font-thin text-xl text-navy-9;
  }

  .text-p1 {
    @apply font-sans font-normal text-xl;
  }

  .text-p1-thin {
    @apply font-sans font-thin text-xl;
  }

  .text-p2 {
    @apply font-sans font-normal text-base;
  }

  .text-p2-thin {
    @apply font-sans font-thin text-base;
  }
}
