@font-face {
  font-family: "GT America";
  src: url("./GT-America-Standard-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT America";
  src:
    url("./GT-America-Standard-Light.woff2") format("woff2"),
    url("./GT-America-Standard-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT America";
  src:
    url("./GT-America-Standard-Regular.woff2") format("woff2"),
    url("./GT-America-Standard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT America";
  src:
    url("./GT-America-Standard-Medium.woff2") format("woff2"),
    url("./GT-America-Standard-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GT America Mono";
  src:
    url("./GT-America-Mono-Regular.woff2") format("woff2"),
    url("./GT-America-Mono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT America Mono";
  src:
    url("./GT-America-Mono-Bold.woff2") format("woff2"),
    url("./GT-America-Mono-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
